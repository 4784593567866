import { useEffect, useState } from 'react'
import './App.css';
import MainBody from './components/MainBody/MainBody';
import Footer from './components/footer/Footer';
import Header from './components/header/Header';

function App() {
  const [Login, setLogin] = useState(false)
 
  useEffect(() => {
   let pin = JSON.parse(sessionStorage.getItem("pin"))
   if(pin != null){
    setLogin(true)
   }
  }, [])

  return (
    <div className="App">
      <Header Login={Login}/>
      <MainBody Login={Login} setLogin={setLogin}/>
      <Footer />
    </div>
  );
}

export default App;
