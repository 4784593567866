import React from 'react'
import eleconLogo from '../elecon.png'
import '../header_footer.css'
import { LuLogOut } from "react-icons/lu";


const Header = (props) => {
 
  

  return (
    <div className='header-container'>
        <div className='elecon-logo'><img src={eleconLogo} height='100%' width='100%' alt='elecon_img'/></div> 
       {props.Login && <div style={{position:"absolute",right:'4%'}}>
          <LuLogOut  size={25} onClick={()=> {
            sessionStorage.clear()
            window.location.reload()
            }}/></div>}
    </div>
  )
}

export default Header