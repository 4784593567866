import React, { useEffect, useState } from "react";
import "./MainBody.css";
import TextField from "@mui/material/TextField";
import { FcLock } from "react-icons/fc";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { Typography } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Universal } from "./BackendURL";
//import Data from "../Data";



const AuthScreen = (props) => {
  const [pin, setPin] = useState(0);


  const authHandler = () => {
    console.log(pin, typeof pin);
    if (pin === "1234") {
      console.log("suthHandler");
      sessionStorage.setItem("pin", JSON.stringify(pin));
      props.setAuth(true);
    } else {
        notify()
    }
  };


  const notify = () => toast.error("Invalid Key!", {position: toast.POSITION.TOP_RIGHT});

  return (
    <div className="auth-container">
      <div className="icon-container">
        <FcLock size={50} color="#f26f31" />
      </div>
      <TextField
        id="outlined-basic"
        label="Your Key"
        variant="outlined"
        type="number"
        sx={{ width: "80%", marginBottom: "10px" }}
        onChange={(e) => setPin(e.target.value)}
      />
      <Button
        style={{ backgroundColor: "#f26f31" }}
        variant="contained"
        sx={{ width: "80%" }}
        onClick={authHandler}
      >
        Login
      </Button>
    </div>
  );
};

const DataScreen = (props) => {
  const [data, setData] = useState([])
  useEffect(() => {
    let pin = JSON.parse(sessionStorage.getItem("pin"));

    if (pin === null) {
      props.setAuth(false);
    }
    else{
     const fetchData = async () =>{
      try {
        const res = await fetch(Universal +"/excel/readExcel",{
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Access-Control-Allow-Origin": "*",
          },
        })
        const resData = await res.json()
        setData(resData.data)
      } catch (error) {
        toast.error("Something Went Wrong! Check Internet Connection and Try Again")
      }
     
     }
     fetchData()
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="data-screen-container">


    {data.map((element, index)=>{
      return(
        <Accordion key={index} sx={{margin:'10px', backgroundColor:`${element.color}`, borderRadius:'15px', boxShadow:'0', border:'0'}}>
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>{element.header}</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <TableContainer component={Paper}>
      <Table sx={{ minWidth: 250 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell align="right">Phone Number</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>

          {element.data.map((row, index) => (
            <TableRow
              key={index}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.Name}
              </TableCell>
              <TableCell align="right">
                <a href={`tel:${row.Mobile}`}>{row.Mobile}</a>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
        </AccordionDetails>
      </Accordion>
      )
})}
    
      </div>
  );
};

const MainBody = (props) => {

  useEffect(() => {
    let pin = JSON.parse(sessionStorage.getItem("pin"));

    if (pin === null) {
      props.setLogin(false);
    } else {
      props.setLogin(true);
    }
  }, []);


  return (
    <div className="main-body-container">
      <ToastContainer/>
      {!props.Login ? (
        <AuthScreen setAuth={props.setLogin} />
      ) : (
        <DataScreen setAuth={props.setLogin} />
      )}
    </div>
  );
};

export default MainBody;
